.projects-gallery-layout.fancybox-is-open .fancybox-bg {
	background: $black;
	opacity: 1;
}

.modal-form.fancybox-is-open .fancybox-bg {
	background: $black;
	opacity: $layer-bg;
}

.modal-form--black.fancybox-is-open .fancybox-bg {
	opacity: 1;
}

.fancybox-loading {
	border: 2px solid #888;
	border-bottom-color: #fff;
}
