.banner-slider {
	$self: &;

	&--active {

		#{$self}__btn-prev {

			@media (min-width: $screen-md) {
				display: block;
			}
		}

		#{$self}__btn-next {

			@media (min-width: $screen-md) {
				display: block;
			}
		}

		#{$self}__mobile-tip {
			display: flex;

			@media (min-width: $screen-md) {
				display: none;
			}
		}
	}

	&__item {
		height: auto;
		box-sizing: border-box;
	}

	&__btn-prev,
	&__btn-next {
		display: none;
	}

	&__mobile-tip {
		display: none;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		position: absolute;
		left: 50%;
		bottom: 18px;
		z-index: 1;
		padding: 0 27px;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;
		color: rgba($white, 0.7);
		transform: translateX(-50%);
	}

	&__mobile-tip-title {
		pointer-events: none;

		&--black {
			color: #000000;
		}
	}

	&__mobile-btn {
		position: absolute;
		top: 50%;
		width: 8px;
		height: 15px;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		border: none;
		border-radius: 0;
		transform: translateY(-50%);
		overflow: hidden;
		user-select: none;

		&--prev {
			left: 0;
			background-image: url("data:image/svg+xml,%3Csvg width='43' height='78' viewBox='0 0 43 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.81698 39.9188L41.1609 75.2628L38.4939 77.9298L0.482911 39.9188L39.8159 0.585855L42.4829 3.25288L5.81698 39.9188Z' fill='white'/%3E%3C/svg%3E%0A");

			&_black {
				background-image: url("data:image/svg+xml,%3Csvg width='43' height='78' viewBox='0 0 43 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.81698 39.9188L41.1609 75.2628L38.4939 77.9298L0.482911 39.9188L39.8159 0.585855L42.4829 3.25288L5.81698 39.9188Z' fill='black'/%3E%3C/svg%3E%0A");
			}
		}

		&--next {
			right: 0;
			background-image: url("data:image/svg+xml,%3Csvg width='43' height='78' viewBox='0 0 43 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.1488 38.5968L1.80489 3.25285L4.47192 0.585815L42.4829 38.5968L3.14994 77.9298L0.48291 75.2627L37.1488 38.5968Z' fill='white'/%3E%3C/svg%3E%0A");

			&_black {
				background-image: url("data:image/svg+xml,%3Csvg width='43' height='78' viewBox='0 0 43 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M37.1488 38.5968L1.80489 3.25285L4.47192 0.585815L42.4829 38.5968L3.14994 77.9298L0.48291 75.2627L37.1488 38.5968Z' fill='black'/%3E%3C/svg%3E%0A");

			}
		}
	}
}
