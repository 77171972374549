.desc-slider {
	$self: &;

	&--project-card {

		#{$self}__item {

			@media (min-width: $screen-md) {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				padding-right: 40px;
				padding-left: 40px;
			}
		}

		#{$self}__content {

			@media (min-width: $screen-md) {
				text-align: left;
			}

			p {

				@media (min-width: $screen-md) {
					color: $white;
				}
			}
		}
	}

	&__item {
		box-sizing: border-box;
	}

	&__item--active {

		#{$self}__content {
			opacity: 1;
			transition-delay: 0.4s;
		}
	}

	&__content {
		position: relative;
		opacity: 0;
		transition: opacity 0.4s cubic-bezier(.56,.1,.34,.91);

		p {
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 24px;
			text-transform: uppercase;
			color: rgba($white, 0.7);

			@media (min-width: $screen-md) {
				font-size: 18px;
				line-height: 26px;
				color: $white;
			}
		}

		:last-child {
			margin-bottom: 0;
		}

		&--align_center {
			text-align: center;
		}
	}

	&__lang-btn-wrap {
		display: none;
/*
		@media (min-width: $screen-md) {
			display: block;
			position: absolute;
			top: 0;
			right: -74px;
			width: auto;
		}*/
	}
}
