input,
textarea {
	font-family: $font-family;
	-webkit-appearance: none;
	border-radius: 0;

	&:-webkit-autofill {
		-webkit-animation-name: autofill;
		-webkit-animation-fill-mode: both;
		-webkit-text-fill-color: rgba($white, 0.7);
		-webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
{
	-webkit-box-shadow: 0 0 0px 1000px $black inset !important;
}


.form-input,
.form-textarea {
	padding-top: 4px;
	padding-right: 50px;
	padding-bottom: 4px;
	padding-left: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	text-transform: uppercase;
	color: rgba($white, 0.7);
	background-color: transparent;
	border: none;
	border-bottom: 1px solid $white;
	transition-property: color, border-color;
	transition-duration: $transition-time;
	-webkit-user-select: text;

	&:active,
	&:focus {
		outline: none;

		&::placeholder {}
	}

	&::placeholder {
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		color: rgba($white, 0.7);
		transition: color $transition-time;
	}
}

.form-textarea {
	display: block;
	resize: none;
}

.form-input-label {
	position: absolute;
	bottom: 4px;
	right: 0;
	font-family: $font-family;
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
	color: $white;
	transition: color $transition-time;
}

.form-input.invalid + .form-input-label {
	color: red;
}


// fix autocomplete

@keyframes autofill {

	0%,
	100% {
		color: rgba($white, 0.7);
		background: transparent;
	}
}

@-webkit-keyframes autofill {

	0%,
	100% {
		color: rgba($white, 0.7);
		background: transparent;
	}
}

.input-group {

	.input-wrap {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}

}

.input-wrap {
	position: relative;
}
