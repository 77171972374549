.desc-slider-wrapper {
	position: relative;

	@media (max-width: $screen-md - 1) and (orientation: landscape) {
		margin-right: -20px;
		margin-left: -20px;
		padding-right: unquote('max(20px, constant(safe-area-inset-right))');
		padding-left: unquote('max(20px, constant(safe-area-inset-right))');
		padding-right: unquote('max(20px, env(safe-area-inset-right))');
		padding-left: unquote('max(20px, env(safe-area-inset-right))');
	}
}
