.request {
	$self: &;
	width: 100%;
	padding-top: 42px;

	@media (max-width: $screen-md - 1) and (orientation: landscape) {
		padding-right: unquote('max(20px, constant(safe-area-inset-right))');
		padding-left: unquote('max(20px, constant(safe-area-inset-right))');
		padding-right: unquote('max(20px, env(safe-area-inset-right))');
		padding-left: unquote('max(20px, env(safe-area-inset-right))');
	}

	&--desktop {

		@media (min-width: $screen-md) {
			position: relative;
			padding-top: 0;
		}

		#{$self}__btn-wrap {
			justify-content: flex-start;
			width: 100%;
			padding-top: 50px;
		}

		#{$self}__submit {
			width: 100%;
		}
	}

	&__container {
	    @include container();
	}

	&__row {
	    @include row();
	}

	&__col {
	    @include col(xs, 12, 12);
	}

	&__wrapper {

		@media (orientation: landscape) {
			margin-right: -20px;
			margin-left: -20px;
		}
	}

	&__form-input {
		width: 100%;
	}

	&__form-textarea {
		width: 100%;
	}

	&__btn-wrap {
		//padding-top: 218px;
		padding-top: 115px;
	}
}

.request-success {
	display: none;
	flex: 1;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	visibility: hidden;

	&__text {
		margin-bottom: 61px;
		font-weight: 200;
		font-size: 24px;
		line-height: 40px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: $white;
	}
}
