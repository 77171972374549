.s-room {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	&__mobile {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 52px;

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__desktop {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			margin-bottom: 60px;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&__intro {
		margin-bottom: 13px;
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		text-transform: uppercase;
		color: $white;

		&--desktop {
			margin-bottom: 0;
		}
	}

	&__title {
		display: inline-flex;
		position: relative;
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 300;
		font-size: 32px;
		line-height: 40px;
		text-align: center;
		text-transform: uppercase;
		color: $white;

		&::after {
			content: "";
			position: absolute;
			top: 3px;
			left: 100%;
			width: 21px;
			height: 21px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAERSURBVHgB3ZS9SgNBFEa/FUnnDxaChZjeRhAbsdhHibWlhYKC6XwBH0AEG0HEInXKlEkXSBECgZAqCSlTZHICEwjLzE52E1LkwGHYy36XuTvDSjuDMSbGb2xgFUtaBxq8GTf/GCkrhIq2wRRf8BxvsGnrZWWF0LsNPyXqJzjEti+7Jz9ndq0tF6MoGrB08CJP0wVTR22Ult1XmPnpP7Ne2ma/eKg80OjTBPBl08Y/0iax12lswjwow05LeKAw966i76DuEs8NaxHjpfo1uy1wzSYKwYt/iTHLth47PkEhmfeN39JqtFy79DX90mp8KAuM9WjSqSsPBF+x52j4g8e+XPCfSPiU5RavsI8VvmNX22YGvyI5qSNrny0AAAAASUVORK5CYII=');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
	}

	// Mixes

	&__btn {
		min-width: 179px;
		margin-top: 73px;

		@media (min-width: $screen-md) {
			margin-top: 133px;
		}
	}
}
