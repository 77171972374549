.video-preview {

	&__title {

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__btn-wrap {

		@media (min-width: $screen-md) {
			display: none;
		}
	}
}
