.btn-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-top: 55px;
	padding-bottom: 83px;

	@media (min-width: $screen-md) {
		padding-top: 80px;
		padding-bottom: 0;
	}

	&--social {
		flex-direction: column;
		max-width: 179px;
		margin: 0 auto;
	}

	&__social-list {
		padding-top: 24px;
	}
}
