.team-list {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0;
	list-style: none;
	width: 100%;

	&__item {
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		width: calc(16.66666666666667% - 1px);
		margin-right: 1px;

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			width: calc(16.66666666666667% - 1px);
		}

		@media (min-width: $screen-md) {
			width: calc(16.66666666666667% - 4px);
			margin: 2px;
		}

		&:hover {

			#{$self}__img-wrap {

				&::after {

					@media (min-width: $screen-md) {
						background-color: rgba($black, 0.5);
					}
				}
			}

			#{$self}__info {

				@media (min-width: $screen-md) {
					opacity: 1;
				}
			}
		}
	}

	&__img-wrap {
		position: relative;
		width: 100%;
		user-select: none;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition-property: background-color;
			transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
			transition-duration: 0.6s;
		}
	}

	&__img-ratio {
		position: relative;
		padding-top: 133.3333%;
		height: 0;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}


	&__info {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 16px 16px 16px;
		min-height: 94px;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		text-align: center;
		text-transform: uppercase;
		color: $white;
		opacity: 0;
		transition: opacity;
		transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
		transition-duration: 0.6s;
		user-select: none;

		@media (min-width: $screen-xl) {
			min-height: 114px;
		}
	}
}
