.projects-list {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 -20px;
	padding: 0;
	list-style: none;

	@media (min-width: $screen-md) {
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 0 -31px;
	}

	&__item {
		width: 100%;
		overflow: hidden;

		@media (min-width: $screen-md) {
			width: calc(50% - 2px);
			margin-right: 4px;
			margin-bottom: 31px;
		}

		&:nth-child(2n) {

			@media (min-width: $screen-md) {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;
		position: relative;

		&:hover,
		&:active {

			#{$self}__title {
				color: $link-color--hover;
			}
		}
	}

	&__img-wrap {
		position: relative;
		height: 0;
		padding-top: 62.3608%;

		&::after {

			@media (min-width: $screen-lg) {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: transparent;
				transition: background-color $transition-time cubic-bezier(0.52, 0.16, 0.24, 1);
			}
		}

		&:hover {

			&::after {

				@media (min-width: $screen-lg) {
					background-color: rgba($black, 0.3);
				}
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 61px;
		padding-top: 18px;
		padding-right: 20px;
		padding-left: 20px;
		background-color: rgba($black, 0.75);

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			padding-right: unquote('max(20px, constant(safe-area-inset-right))');
			padding-left: unquote('max(20px, constant(safe-area-inset-right))');
			padding-right: unquote('max(20px, env(safe-area-inset-right))');
			padding-left: unquote('max(20px, env(safe-area-inset-right))');
		}

		@media (min-width: $screen-md) {
			position: relative;
			bottom: auto;
			left: auto;
			height: auto;
			padding: 7px 0 0;
			background-color: transparent;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 300;
		font-size: 18px;
		line-height: 26px;
		text-transform: uppercase;
		color: $white;
		transition: color $transition-time;
	}
}
