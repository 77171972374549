.page-footer {
	padding-top: 250px;
	padding-bottom: 25px;

	&--no-padding {
		padding-top: 0;
	}

	&--video {
		padding-top: 40px;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__copyright {
		@include col(md, 6, 12);
		@include col-offset(md, 3, 12);
		@include col(xl, 4, 12);
		@include col-offset(xl, 4, 12);
		text-align: center;
	}

	&__social {
		@include col(md, 3, 12);
		@include col(xl, 4, 12);
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
		align-items: center;
	}

	&__text {

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 26px;
			color: rgba($white, 0.5);

			@media (min-width: $screen-lg) {
				font-size: 16px;
				line-height: 24px;
			}
		}

		:last-child {
			margin-bottom: 0;
		}
	}
}
