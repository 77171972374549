.btn {
	$self: &;
	display: inline-block;
	position: relative;
	min-width: 179px;
	max-width: 100%;
	height: auto;
	margin: 0;
	padding: 15px 30px 13px;
	font-family: $font-family;
	font-weight: 400;
	font-size: 18px;
	line-height: 1;
	letter-spacing: 0.05em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: $white;
	background-color: transparent;
	border: 1px solid $white;
	border-radius: 0;
	vertical-align: top;
	overflow: hidden;
	transition: color, background-color, border-color;
	transition-duration: $transition-time;
	user-select: none;
	cursor: pointer;

	&__title {
		white-space: nowrap;
	}

	&:hover {
		color: $white;
		background-color: rgba($white, 0.2);
	}

	&:focus {
		outline: none;
	}

	&--icon {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		height: 60px;
		padding-left: 22px;
		padding-right: 22px;
		text-align: left;
		overflow: hidden;

		#{$self}__img {
			margin-right: 15px;
			flex-shrink: 0;
		}
	}
}

.lang-btn-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	width: 100%;

	@media (min-width: $screen-md) {
		position: absolute;
		top: 0;
		right: -60px;
		width: auto;
	}

	&--project-card {

		@media (min-width: $screen-md) {
			right: 0;
			z-index: 2;
		}
	}
}

.lang-btn {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0 0 0 10px;
	font-family: $font-family;
	font-weight: 400;
	font-size: 12px;
	line-height: 26px;
	text-transform: uppercase;
	white-space: nowrap;
	color: rgba($white, 0.7);
	background-color: transparent;
	border: none;
	border-radius: 0;
	transition: color $transition-time;
	user-select: none;
	cursor: pointer;

	@media (min-width: $screen-md) {
		color: $white;
	}

	&:hover {

		@media (min-width: $screen-md) {
			color: $link-color--hover;
		}
	}

	&:focus {
		outline: none;
	}

	&:active {

		@media (min-width: $screen-md) {
			color: $link-color--hover;
		}
	}

	&__bracket {
		font-size: 24px;
		line-height: 26px;
	}

	&__title {
		margin: 0 2px;
	}
}

.video-btn {
	position: relative;
	display: block;
	width: 153px;
	height: 153px;
	margin: 0;
	padding: 0;
	background-color: rgba($black, 0.75);
	border: none;
	border-radius: 50%;
	cursor: pointer;

	@media (min-width: $screen-md) {
		width: 200px;
		height: 200px;
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 46%;
		height: 46%;
		background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M59.8564 35.4999L22.1529 56.8106L22.1529 14.1892L59.8564 35.4999Z' fill='white'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transform: translate(-50%, -50%);
	}

	&:focus {
		outline: none;
	}
}

.btn-close {
	position: relative;
	width: 25px;
	height: 8px;
	margin: 0;
	padding: 12px;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 50%;
		width: 25px;
		height: 1px;
		background-color: $white;
		transition: all $transition-time;
	}

	&::before {
		transform: translateX(-50%) rotate(45deg);
	}

	&::after {
		transform: translateX(-50%) rotate(-45deg);
	}

	&:focus {
		outline: none;
	}
}

.video-play-btn {
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&::after,
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transition: opacity $transition-time;
	}

	&::after {
		background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34 20L11 33L11 7L34 20Z' fill='white'/%3E%3C/svg%3E%0A");
		opacity: 1;
	}

	&.on-pause {

		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 8H15.9855V31H10V8.00011V8Z' fill='white'/%3E%3Cpath d='M23.9629 8H29.945V31H23.9629V8.00011V8Z' fill='white'/%3E%3C/svg%3E%0A");
			opacity: 1;
		}

		&::after {
			opacity: 0;
		}
	}

	&:focus {
		outline: none;
	}
}

.player-btn {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 12px;
	margin: 0;
	padding: 0;
	font-family: $font-family;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	text-decoration: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	vertical-align: top;
	overflow: hidden;
	user-select: none;
	cursor: pointer;

	&:hover {

		#{$self}__icon {
			fill: $link-color--hover;
		}
	}

	&:active {

		#{$self}__icon {
			fill: $link-color--hover;
		}
	}

	&.is-play {

		#{$self}__icon {
			display: none;
		}

		#{$self}__icon--alternate {
			display: block;
		}
	}

	&__icon {
		flex-shrink: 0;
		fill: $white;
		vertical-align: top;
		transition: fill $transition-time;

		&--alternate {
			display: none;
		}
	}
}
