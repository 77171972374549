.tap-list {
	$self: &;
	margin: 0;
	padding: 0;
	list-style: none;

	&--active {

		#{$self}__item {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}

	&--hidden {

		#{$self}__item {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__item {
		margin-bottom: 8px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(16px);
		transition-property: opacity, visibility, transform;
		transition-duration: 0.85s;
		transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);

		&:nth-child(1) {
			transition-delay: 0s;
		}

		&:nth-child(2) {
			transition-delay: 0.3s;
		}

		&:nth-child(3) {
			transition-delay: 0.6s;
		}

		&:nth-child(4) {
			transition-delay: 0.9s;
		}

		&:nth-child(5) {
			transition-delay: 1.2s;
		}

		&:nth-child(6) {
			transition-delay: 1.5s;
		}

		&:nth-child(7) {
			transition-delay: 1.8s;
		}

		&:nth-child(8) {
			transition-delay: 2.1s;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__btn {
		width: 100%;
	}
}
