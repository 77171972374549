.video-slider-wrapper {
	margin: 0 -20px;
	position: relative;

	@media (min-width: $screen-md) {
		height: calc(100vh - 162px);
		margin: 0;
		padding-right: 4.7777%;
		padding-left: 4.7777%;
	}
}

.video-slider {

	@media (max-width: $screen-sm - 1) {
		overflow: initial;
	}

	@media (min-width: $screen-md) {
		height: 100%;
	}

	&__wrapper {

		@media (max-width: $screen-sm - 1) {
			flex-direction: column;
			box-sizing: border-box;
		}
	}

	&__item {
		height: auto;
		box-sizing: border-box;

		@media (max-width: $screen-sm - 1) {
			margin-bottom: 11px;
		}

		&:last-child {

			@media (max-width: $screen-sm - 1) {
				margin-bottom: 0;
			}
		}
	}

	&__content {

		@media (min-width: $screen-md) {
			position: relative;
			height: 100%;
		}
	}

	&__img-wrap {
		position: relative;
		width: 100%;

		@media (min-width: $screen-md) {
			position: absolute;
			top: 0;
			left:0;
			width: 100%;
			height: 100%;
		}
	}

	&__video-wrap {
		display: none;
		position: relative;
		padding-top: 56.25%;
		height: 0;

		@media (min-width: $screen-md) {
			display: block;
			width: 100%;
			height: 100%;
			padding: 0;
		}
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&__img-ratio {
		position: relative;
		height: 0;
		padding-top: 56.25%;

		@media (min-width: $screen-md) {
			height: 100%;
			padding-top: 0;
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__controls {
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -50%);

		@media (min-width: $screen-md) {
			display: none;
		}

		&--desktop {
			display: none;

			@media (min-width: $screen-md) {
				display: block;
			}
		}
	}

	&__tip {
		position: absolute;
		top: 100%;
		left: 50%;
		font-weight: 400;
		font-size: 10px;
		line-height: 26px;
		text-transform: uppercase;
		color: $white;
		white-space: nowrap;
		transform: translateX(-50%);

		@media (min-width: $screen-md) {
			top: calc(100% + 20px);
			font-size: 15px;
			line-height: 26px;
		}
	}

	&__btn-wrap {
		padding-top: 142px;
	}

	&__navigation {
		display: none;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding-top: 22px;

		@media (min-width: $screen-sm) {
			display: flex;
		}
	}

	&__pagination {
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);

		@media (min-width: $screen-md) {
			//bottom: -40px;
		}
		position: relative;
		bottom: auto;
		left: auto;
		width: auto;
		margin-right: 48px;
		margin-left: 48px;
		transform: none;

	}

	&__button {
		$self: &;
		display: inline-block;
		margin: 0;
		padding: 0;
		font-family: $font-family;
		font-weight: 400;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		color: $white;
		background-color: transparent;
		border: none;
		border-radius: 0;
		vertical-align: top;
		overflow: hidden;
		transition: color, background-color, border-color, opacity;
		transition-duration: $transition-time;
		user-select: none;
		cursor: pointer;

		&:hover {
			opacity: 0.65;
		}

		&:active {
			opacity: 0.65;
		}

		&.swiper-button-disabled {
			opacity: 0.3;
		}
	}

	&__btn-prev,
	&__btn-next {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
		}
	}
}
