.projects {

	&__row {

		@media (min-width: $screen-md) {
			@include row();
		}
	}

	&__header {
		@include col(md, 6, 12);
		@include col-offset(md, 3, 12);
		@include col(lg, 4, 12);
		@include col-offset(lg, 4, 12);
	}

	&__footer {
		@include col(md, 6, 12);
		@include col-offset(md, 3, 12);
		@include col(lg, 4, 12);
		@include col-offset(lg, 4, 12);
	}

	&__title {
		margin-bottom: 0;
	}

	&__intro {
		padding-top: 8px;
		padding-bottom: 42px;

		@media (min-width: $screen-md) {
			padding-top: 23px;
			padding-bottom: 87px;
		}
	}

	&__outro {
		padding-top: 42px;

		@media (min-width: $screen-md) {
			padding-top: 87px;
		}
	}
}

