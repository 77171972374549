.d-contacts {
	$self: &;
	display: none;

	@media (min-width: $screen-md) {
		display: block;
	}

	&__row {
		@include row();
	}

	&__content-col {
		@include col(md, 3, 12);
		@include col-offset(md, 1, 12);
		@include col(xxl, 2, 12);
		@include col-offset(xxl, 1, 12);
	}

	&__map-col {
		@include col(md, 6, 12);
		@include col-offset(md, 1, 12);
		@include col(xxl, 7, 12);
		@include col-offset(xxl, 1, 12);
	}

	&__content {
		position: relative;
		height: 100%;
	}

	&__title {
		margin-bottom: 0;
		font-size: 56px;
		line-height: 64px;
		text-align: left;
	}

	&__sub-title {
		margin-bottom: 48px;
		font-size: 32px;
		line-height: 40px;
		text-align: left;
		color: rgba($white, 0.25);
	}

	&__sub-title-inner {
		position: relative;
		padding-right: 23px;
		white-space: nowrap;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			width: 13px;
			height: 24px;
			background-image: url("data:image/svg+xml,%3Csvg width='13' height='24' viewBox='0 0 13 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25969 23.4822L0.00163093 22.2241L10.4839 11.7419L0 1.25806L1.25807 -4.81475e-05L12.9983 11.7402L13 11.7419L1.25969 23.4822Z' fill='white' fill-opacity='0.25'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			transform: translateY(-50%);
		}
	}

	&__btn-wrap {
		justify-content: flex-start;
		padding-top: 32px;
	}

	&__map-wrapper {
		position: relative;
	}

	&__img {
		opacity: 0.15;
	}

	&__request {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}

	&__request-inner {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&__request-close {
		position: absolute;
		top: -54px;
		right: -25px;
	}

	&__sticker {
		position: absolute;
		padding: 6px;
		cursor: pointer;

		&:hover {

			#{$self}__sticker-inner {
				border-color: $link-color--hover_dark;
			}

			#{$self}__sticker-title {
				color: $link-color--hover_dark;
			}
		}

		&:active {

			#{$self}__sticker-inner {
				border-color: $link-color--hover_dark;
			}

			#{$self}__sticker-title {
				color: $link-color--hover_dark;
			}
		}
	}

	&__sticker-inner {
		width: 12px;
		height: 12px;
		background-color: transparent;
		border: 3px solid $white;
		border-radius: 50%;
		transition: border-color $transition-time;
	}

	&__sticker-title {
		position: absolute;
		bottom: calc(100% - 6px);
		left: 50%;
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		text-transform: uppercase;
		white-space: nowrap;
		color: $white;
		transform: translateX(-50%);
		transition: color $transition-time;
	}
}
