.about {
	$self: &;

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__title {

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__team {


		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			margin: 0 -20px;
			padding-top: 10px;
			padding-right: unquote('max(20px, constant(safe-area-inset-right))');
			padding-left: unquote('max(20px, constant(safe-area-inset-right))');
			padding-right: unquote('max(20px, env(safe-area-inset-right))');
			padding-left: unquote('max(20px, env(safe-area-inset-right))');
		}

		@media (min-width: $screen-md) {
			margin: 0;
		}
	}

	&__text {
		padding-top: 22px;
		padding-bottom: 42px;

		@media (min-width: $screen-md) {
			@include row();
			padding-top: 80px;
			padding-bottom: 100px;
		}

		&--bottom {
			padding-bottom: 0;
		}
	}

	&__text-col {
		@include col(md, 6, 12);
		@include col-offset(md, 3, 12);
	}

	&__desc {
		padding-top: 18px;

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			margin-right: -20px;
			margin-left: -20px;
			padding-right: unquote('max(20px, constant(safe-area-inset-right))');
			padding-left: unquote('max(20px, constant(safe-area-inset-right))');
			padding-right: unquote('max(20px, env(safe-area-inset-right))');
			padding-left: unquote('max(20px, env(safe-area-inset-right))');
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	&__banner {

		.banner__img-wrap {

			@media (max-width: $screen-md - 1) {
				height: 273px;
			}
		}

		@media (min-width: $screen-md) {
			height: auto;
		}
	}

	&__btn-wrap {

		@media (min-width: $screen-md) {
			padding-top: 110px;
		}
	}

	&__desc-slider-wrapper {

		+ #{$self}__desc-slider-wrapper {

			@media (min-width: $screen-md) {
				padding-top: 80px;
			}
		}
	}
}
