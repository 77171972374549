.player {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	&__audio {
		display: none;
	}

	&__label {
		width: 80px;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		color: rgba($white, 0.25);
		text-transform: uppercase;
		white-space: nowrap;
		overflow: hidden;
	}

	&__track {
		color: $white;
	}

	&__controls {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&__btn {
		flex-shrink: 0;
		margin: 0 10px;

		&--play {
			margin: 0;
		}
	}

	&__playlist {
		display: none;
	}

	&__equalizer {
		position: relative;
	}

	&__equalizer {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		width: 28px;
		cursor: pointer;

		&--active {

			#{$self}__equalizer-item {
				animation-name: equalizer;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;

			}
		}
	}

	&__equalizer-item {
		flex-grow: 0;
		flex-shrink: 0;
		width: 1px;
		height: 20px;
		background-color: $white;
		transform-origin: 50% 50%;
		transform: scaleY(0.2);

		&:nth-child(1) {
			animation-delay: 0.15s;
		}

		&:nth-child(2) {
			animation-delay: calc(0.15s * 2);
		}

		&:nth-child(3) {
			animation-delay: calc(0.15s * 3);
		}

		&:nth-child(4) {
			animation-delay: calc(0.15s * 4);
		}

		&:nth-child(5) {
			animation-delay: calc(0.15s * 5);
		}

		&:nth-child(6) {
			animation-delay: calc(0.15s * 6);
		}

		&:nth-child(7) {
			animation-delay: calc(0.15s * 7);
		}

		&:nth-child(8) {
			animation-delay: calc(0.15s * 8);
		}

		&:nth-child(9) {
			animation-delay: calc(0.15s * 9);
		}

		&:nth-child(10) {
			animation-delay: calc(0.15s * 10);
		}
	}
}

@keyframes equalizer {

	0% {
		transform: scaleY(0.2);
	}

	25% {
		transform: scaleY(0.5);
	}

	50% {
		transform: scaleY(0.2);
	}

	75% {
		transform: scaleY(0.8);
	}

	100% {
		transform: scaleY(0.2);
	}
}
