.section-title {
	margin-top: 0;
	margin-bottom: 42px;
	font-weight: 300;
	font-size: 32px;
	line-height: 40px;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	color: $white;

	@media (min-width: $screen-md) {
		margin-bottom: 23px; /* ??? */
		font-size: 56px;
		line-height: 64px;
	}

	&--link {
		margin: 0;
	}
}
