.modal-wrapper {
	padding: 0;
	vertical-align: middle;
}

.modal {
	$self: &;
	display: none;
	width: 100%;
	height: 100%;
	padding: 0;
	background-color: transparent;
	//background-color: rgba(lime, 0.25);

	&--form,
	&--team {

		#{$self}__inner {
			justify-content: flex-start;
			padding: 0;
		}
	}

	&--team {

		#{$self}__content {
			flex: 1;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100%;
		height: auto;
		//background-color: rgba(red, 0.5);

		@media (orientation: landscape) {
			justify-content: flex-start;
			padding-top: 61px;
			padding-bottom: 61px;
/*			margin-top: 61px;
			margin-bottom: 61px;*/
		}
	}

	&__header {
		position: relative;
		width: 100%;
		height: 61px;
		background-color: transparent;

		@media (orientation: landscape) {
			padding-right: unquote('max(20px, constant(safe-area-inset-right))');
			padding-left: unquote('max(20px, constant(safe-area-inset-right))');
			padding-right: unquote('max(20px, env(safe-area-inset-right))');
			padding-left: unquote('max(20px, env(safe-area-inset-right))');
		}

		&--fixed {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 99999;
			width: 100%;
			height: 61px;

			@media (orientation: landscape) {
				padding-right: unquote('max(20px, constant(safe-area-inset-right))');
				padding-left: unquote('max(20px, constant(safe-area-inset-right))');
				padding-right: unquote('max(20px, env(safe-area-inset-right))');
				padding-left: unquote('max(20px, env(safe-area-inset-right))');
			}
		}

		&--theme_black {
			background-color: $black;
		}
	}

	&__header-container {
		@include container();
		height: 100%;
	}

	&__header-row {
		@include row();
		height: 100%;
	}

	&__header-col {
		@include col(xs, 12, 12);
		height: 100%;
	}

	&__header-wrapper {
		position: relative;
		height: 100%;
		@media (orientation: landscape) {
			margin: 0 -20px;
		}
	}

	&__logo-link {
		display: block;
		position: absolute;
		top: 22px;
		left: 0;
		height: 18px;
		opacity: 1;
		transition-property: visibility, opacity;
		transition-timing-function: cubic-bezier(0.32, 0.08, 0.24, 1);
		transition-duration: 0.6s;

		@media (orientation: landscape) {
			//left: 27px;
		}
	}

	&__logo-img {
		height: 100%;
		width: auto;
		vertical-align: top;
	}

	&__close-btn {
		position: absolute;
		top: 18px;
		right: 0;
		z-index: 1;

		@media (orientation: landscape) {
			//right: 27px;
		}
	}
}


