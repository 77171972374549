.team {


}

.team-card {
	flex-grow: 0;
	flex-shrink: 0;
	width: 59.2%;

	&__img-wrap {
		width: 100%;
	}

	&__img-ratio {
		position: relative;
		padding-top: 133.3333%;
		height: 0;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';
	}

	&__info {
		padding: 6px 18px 8px 18px;
		background-color: $black;
		text-align: left;
	}

	&__name,
	&__position {
		font-weight: 200;
		font-size: 18px;
		line-height: 26px;
		text-transform: uppercase;
	}

	&__name {
		margin: 0;
		/* TODO: в макете используется начертание light (проверить) */
	}

	&__position {
		color: rgba($white, 0.7);
	}
}
