.banner {
	position: relative;

	@media (min-width: $screen-md) {
		width: 100%;
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&__heading {
		position: absolute;
		display: block;
		width: 100%;
		bottom: 30px;

		@media (min-width: $screen-md) {
			padding-left: 88px;
			bottom: 60px;
		}
	}

	&__title {
		margin: 0;
		font-weight: 400;
		font-size: 56px;
		line-height: 1;
		text-transform: uppercase;
		color: rgba($white, 0.7);
		text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

		strong {
			font-weight: 400;
			color: $white;
		}

		@media (max-width: $screen-md - 1) {
			font-size: 20px;
			line-height: 24px;
		}
	}

	&__img-wrap {
		width: 100%;
		height: 100%;

		@media (min-width: $screen-md) {
			height: 0;
			padding-top: 42.2916%;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		font-family: 'object-fit: cover; object-position: center center;';

		@media (min-width: $screen-md) {
			position: absolute;
			top: 0;
			left: 0;
			transform: scale3d(1, 1, 1);
		}
	}
}
