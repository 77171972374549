.preloader {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;
	height: 100%;
	background-color: $black;
}

.spinner {
	animation: spinner-rotate 1s linear infinite;
	background: transparent;
	border: 2px solid #888;
	border-bottom-color: #fff;
	border-radius: 50%;
	height: 50px;
	left: 50%;
	margin: -25px 0 0 -25px;
	opacity: .7;
	padding: 0;
	position: absolute;
	top: 50%;
	width: 50px;
	z-index: 99999;
}

@keyframes spinner-rotate {

	100% {
		transform: rotate(360deg);
	}
}
