@at-root {

  @-ms-viewport {
    width: device-width;
  }
}

@font-face {
	font-family: 'PF Din Text Comp Pro';
	src: url('../fonts/PFDinTextCompPro-Thin.woff2') format('woff2'),
	url('../fonts/PFDinTextCompPro-Thin.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'PF Din Text Comp Pro';
	src: url('../fonts/PFDinTextCompPro-Light.woff2') format('woff2'),
	url('../fonts/PFDinTextCompPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'PF Din Text Comp Pro';
	src: url('../fonts/PFDinTextCompPro-Regular.woff2') format('woff2'),
	url('../fonts/PFDinTextCompPro-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'PF Din Text Comp Pro';
	src: url('../fonts/PFDinTextCompPro-Bold.woff2') format('woff2'),
	url('../fonts/PFDinTextCompPro-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

.page {
	$self: &;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-width: 320px;
  min-height: 100%;

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
		padding-top: 61px;

		@media (min-width: $screen-md) {
			padding-top: 82px;
		}

		&--centered {

			#{$self}__content {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
			}
		}
  }

  &__content {
    flex-grow: 1;

		&--contacts {

			@media (min-width: $screen-md) {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
			}
		}

		&--secret {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
		}
  }

	&__main {

		&--cap {
			display: none;

			@media (min-width: $screen-md) {
				display: flex;
			}
		}
	}

  &__footer-wrapper {
		display: none;
    flex-shrink: 0;

		@media (min-width: $screen-md) {
			display: block;
		}
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  -webkit-tap-highlight-color: transparent;

	&:focus {
		outline: none;
	}
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100%;
	font-weight: 400;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  text-align: left;
  color: $text-color;
  background-color: $body-bg;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
	vertical-align: middle;
}

a {
  color: $link-color;
  background-color: transparent;
  transition: color 0.3s;
	text-decoration: none;

  &:hover {
    color: $link-color--hover;
  }

	&:focus {
		outline: none;
	}
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: $typo-margin-vertical * 2;
  margin-bottom: $typo-margin-vertical * 2;
  border: 0;
  border-top: 1px solid $border-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: $font-family;
  font-weight: 700;
  line-height: 1.2;
  color: currentColor;
}

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: $typo-margin-vertical;
}

p {
	margin-bottom: 10px;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	color: rgba($white, 0.7);
}

address {
  font-style: normal;
}

ul,
ol {
  margin-bottom: $typo-margin-vertical;
  padding-left: 1.5em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

small {
  font-size: $font-size-sm;
}

sub,
sup {
  font-size: $font-size-sm;
}


b,
strong {
  font-weight: bolder;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre {
  font-family: $font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: rgba(#000, 0.08);
  border-radius: $border-radius;
}

kbd {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: $gray-lightest;
  border-radius: $border-radius;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  width: 100%;
  margin-bottom: $typo-margin-vertical;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);

  code {
    background: transparent;
  }
}

