.tabs {

	&__input-wrap {
		position: relative;
		width: 100%;
		margin-bottom: 38px;
	}
}

.tabs-select {
	width: 100%;
	height: 26px;
	opacity: 0;
}

.tabs-select-label {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-right: 30px;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	text-transform: uppercase;
	color: rgba($white, 0.5);
	pointer-events: none;

	&::after {
		content: "";
		position: absolute;
		top: calc(50% - 2px);
		right: 0;
		width: 20px;
		height: 12px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.89968L1.06066 0.839021L9.89811 9.67647L18.7369 0.837646L19.7976 1.89831L9.89949 11.7964L9.89811 11.7978L0 1.89968Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transform: translateY(-50%);
	}
}

.tabs-content {
	display: none;

	&--active {
		display: block;
	}
}
