.social-list {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;

	&--footer {
		display: inline-flex;
		width: auto;
		overflow: hidden;

		#{$self}__item {
			margin-right: 32px;
			opacity: 0;
			visibility: hidden;
			transform: translateY(16px);
			transition-property: visibility, opacity, transform;
			transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
			transition-duration: 0.4s;

			&:last-child {
				margin-right: 0;
			}

			&.is-revealed {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}

		#{$self}__link {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			padding: 0 4px;
		}
	}

	&--contacts {
		justify-content: center;

		#{$self}__item {
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__link {
		display: block;

		&:hover {
			color: $white;

			#{$self}__icon {
				fill: $link-color--hover;
			}
		}

		&:active {
			color: $white;

			#{$self}__icon {
				fill: $link-color--hover;
			}
		}
	}

	&__icon {
		fill: $white;
		transition: fill $transition-time;
		vertical-align: top;
	}
}
