.visuallyhidden {
	@include visuallyhidden();
}

.section {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	padding-top: 42px;

	@media (max-width: $screen-md - 1) and (orientation: landscape) {
		padding-top: 48px;
	}

	@media (min-width: $screen-md) {
		padding-top: 118px;
	}

	&--fluid {
		max-width: none;
		margin: 0;
	}

	&--no-padding {
		padding: 0;
	}

	&--no-padding_md {

		@media (min-width: $screen-md) {
			padding-top: 0;
		}
	}

	&--padding-bottom {
		padding-bottom: 42px;

		@media (orientation: landscape) {
			padding-bottom: 48px;
		}
	}

	&__container {
		@include container();
	}

	&__row {
		@include row();
	}

	&__col {
		@include col(xs, 12, 12);
	}

	&--contacts {

		@media (min-width: $screen-md) {
			padding: 0;
		}
	}

	&--secret {

		@media (max-width: $screen-md - 1) {
			padding-top: 96px;
			padding-bottom: 96px;
		}
	}
}

.title-link {
	display: block;
	padding-top: 42px;
	padding-bottom: 42px;
	text-decoration: none;
	color: inherit;

	@media (orientation: landscape) {
		padding-top: 48px;
	}

	&:hover {
		color: $white;
	}

	&:focus {

	}

	&:active {
		color: $white;
	}
}

.page-menu-trigger {
	position: absolute;
	top: 61px;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 70vh;
	//margin-bottom: 61px;
	//background-color: rgba(goldenrod, 0.5);
	/* TODO: перенести это отсюда */
}

.cap {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	text-align: center;

	&__img {
		margin-bottom: 30px;
	}

	&__text {
		font-weight: 200;
		font-size: 18px;
		line-height: 26px;
		text-transform: uppercase;
	}
}

.fancybox-image,
.fancybox-content {
/*	touch-action: none !important;*/
}
