.video-progress {
	position: relative;
	width: 200px;
	height: 10px;
	padding: 4px 0;

	&::after {
		content: "";
		position: absolute;
		top: 4px;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 2px;
		background-color: rgba($black, 0.7);
	}


	&__line {
		width: auto;
		height: 2px;
		position: absolute;
		top: 4px;
		left: 0;
		background-color: $white;
		transition: width 0.6s ease;
		will-change: width;
	}

	&__indicator {
		position: absolute;
		top: 50%;
		right: 0;
		width: 2px;
		height: 16px;
		transform: translateY(-50%);
		background-color: $white;
	}
}
