/*base code*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}
/*the animation definition*/
@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}
@keyframes fadeInLeft {

	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0)
	}
	100% {
		opacity: 1;
		transform: none
	}
}

.fadeInLeft {
	animation-name: fadeInLeft
}

@keyframes fadeInDown {

	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0)
	}
	100% {
		opacity: 1;
		transform: none
	}
}

.fadeInDown {
	animation-name: fadeInDown
}

@keyframes fadeInUp {

	0% {
		opacity: 0;
		transform: translate3d(0, -100%, 0)
	}
	100% {
		opacity: 1;
		transform: none
	}
}

.fadeInUp {
	animation-name: fadeInUp
}

@keyframes fadeOutUp {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		transform: translate3d(0, -100%, 0)
	}
}

.fadeOutUp {
	animation-name: fadeOutUp
}

// Text

.ta1 .letter {
	display: inline-block;
	opacity: 0;
}

.ta2 .letter {
	display: inline-block;
		opacity: 0;
}

// Cards

@keyframes fadeUpCard {

	0% {
		opacity: 0;
		transform: translate3d(0, 5%, 0);
	}

	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

}
