// Arrows

.swiper-button-next--video,
.swiper-button-prev--video {
	top: 50%;
	width: 4.7777%;
	height: 100%;
	margin-top: 0;
	background: transparent;
	border: none;
	transform: translateY(-50%);
	transition: border-color;
	transition-duration: $transition-time;
	outline: none;

	&::before,
	&::after {
		content: none;
	}
}

.swiper-button-next--video {
	right: 0;
}

.swiper-button-prev--video {
	left: 0;
}

// banner

.swiper-button-next--banner,
.swiper-button-prev--banner {
	top: 50%;
	width: 80px;
	height: 80px;
	margin-top: 0;
	background: rgba($black, 0.5);
	border: none;
	border-radius: 50%;
	transform: none;
	outline: none;

	&:hover {

		&::after {
			opacity: 0.5;
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		width: 20px;
		height: 38px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		transform: translate(-50%, -50%);
		transition: opacity;
		transition-duration: $transition-time;
	}
}

.swiper-button-next--banner {
	top: auto;
	right: 60px;
	bottom: 50px;

	&::after {
		left: calc(50% + 2px);
		background-image: url("data:image/svg+xml,%3Csvg width='42' height='80' viewBox='0 0 42 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.52588e-05 77.7817L39.598 38.1837L41.0122 39.5979L1.41423 79.1959L1.52588e-05 77.7817Z' fill='%23BCBCBC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.41423 -3.8147e-05L41.0122 39.5979L39.598 41.0122L1.52588e-05 1.41418L1.41423 -3.8147e-05Z' fill='%23BCBCBC'/%3E%3C/svg%3E%0A");
	}
}

.swiper-button-prev--banner {
	top: auto;
	bottom: 50px;
	left: 60px;

	&::after {
		left: calc(50% - 2px);
		background-image: url("data:image/svg+xml,%3Csvg width='42' height='80' viewBox='0 0 42 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.4142 1.81619L1.81619 41.4142L0.401978 40L40 0.401978L41.4142 1.81619Z' fill='%23BCBCBC'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 79.5979L0.401978 40L1.81619 38.5857L41.4142 78.1837L40 79.5979Z' fill='%23BCBCBC'/%3E%3C/svg%3E%0A");
	}
}

// Pagination

.swiper-pagination-bullets {
	bottom: 0;
	font-size: 0;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
	position: relative;
	width: 21px;
	height: 3px;
	margin: 0 3px;
	padding-top: 16px;
	padding-bottom: 16px;
	background-color: transparent;
	border-radius: 0;
	opacity: 1;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 3px;
		transform: translateY(-50%);
		background-color: rgba($white, 0.5);
		transition: background-color $transition-time;
	}
}

.swiper-pagination-bullets .swiper-pagination-bullet-active {
	position: relative;

	&::after {
		background-color: rgba($white, 0.125);
	}
}
