.address-list {
	$self: &;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		display: none;

		&--active {
			display: block;

			#{$self}__place {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}

			#{$self}__contacts {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}

	&__place {
		width: 100%;
		margin-top: 0;
		margin-bottom: 4px;
		font-weight: 400;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: normal;
		text-transform: uppercase;
		color: $white;
		opacity: 0;
		visibility: hidden;
		transform: translateY(16px);
		transition-property: opacity, visibility, transform;
		transition-duration: 0.85s;
		transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);

	}

	&__contacts {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		margin-bottom: 16px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(16px);
		transition-property: opacity, visibility, transform;
		transition-duration: 0.85s;
		transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);

		@media (min-width: $screen-lg) {
			margin-bottom: 32px;
		}
	}

	&__link-wrap {
		margin-right: 32px;
		color: rgba($white, 0.25);

		&:last-child {
			margin-right: 0;
		}

		+ #{$self}__link-wrap {
			margin-left: auto;
		}
	}

	&__link {
		font-weight: 400;
		font-size: 18px;
		line-height: 26px;
		text-transform: uppercase;
		text-decoration: none;
		white-space: nowrap;
		color: rgba($white, 0.25);

		&:hover {
			color: $white;

			@media (min-width: $screen-md) {
				color: $link-color--hover;
			}
		}

		&:focus {
			outline: none;
		}

		&:active {
			color: $white;

			@media (min-width: $screen-md) {
				color: $link-color--hover;
			}
		}
	}
}
