.i-code {
	$self: &;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;


	&__item {
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__input {
		width: 47px;
		height: 40px;
		margin-right: 8px;
		padding: 0;
		font-family: $font-family;
		font-weight: 500;
		font-size: 40px;
		line-height: 40px;
		text-align: center;
		color: $white;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid $white;
		border-radius: 0;
		box-shadow: none;
		transition-property: color, border-color;
		transition-duration: $transition-time;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:last-child {
			margin-right: 0;
		}

		&.invalid {
			color: red;
			border-color: red;
		}
	}
}
