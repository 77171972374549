.page-header {
	$self: &;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	height: 61px;
	background-color: $layer-bg;
	transition: background 0.4s cubic-bezier(0.32, 0.08, 0.24, 1),
							height 0.6s cubic-bezier(0.52, 0.16, 0.24, 1);

	@media (max-width: $screen-md - 1) and (orientation: landscape) {
		padding-right: unquote('max(20px, constant(safe-area-inset-right))');
		padding-left: unquote('max(20px, constant(safe-area-inset-right))');
		padding-right: unquote('max(20px, env(safe-area-inset-right))');
		padding-left: unquote('max(20px, env(safe-area-inset-right))');
	}

	@media (min-width: $screen-md) {
		height: 82px;
		background-color: $layer-bg--light;
	}

	&--opened {
		height: 100%;
		transition: background 0.4s cubic-bezier(0.32, 0.08, 0.24, 1),
		height 0.6s cubic-bezier(0.52, 0.16, 0.24, 1);

		#{$self}__logo-link {
			visibility: hidden;
			opacity: 0;
		}

		#{$self}__nav {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		#{$self}__copyright {
			visibility: visible;
			animation: fadeInUp 1s 1.4s cubic-bezier(.25, .1, .25, 1) both;
		}
	}

	&--content {
		height: 100%;
		transition: background 0.4s cubic-bezier(0.32, 0.08, 0.24, 1),
		height 0.6s cubic-bezier(0.52, 0.16, 0.24, 1);

		#{$self}__logo-link {
			visibility: hidden;
			opacity: 0;
		}

		#{$self}__content {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		#{$self}__copyright {
			visibility: visible;
			animation: fadeInUp 1s 1.2s cubic-bezier(.25, .1, .25, 1) both;
		}
	}

	&__container {
		@include container();
		height: 100%;
	}

	&__row {
		@include row();
		height: 100%;
	}

	&__col {
		@include col(xs, 12, 12);
		height: 100%;
	}

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		height: 100%;

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			margin-right: -20px;
			margin-left: -20px;
		}

		@media (min-width: $screen-md) {
			flex-direction: row;
			align-items: center;
		}
	}

	&__logo-link {
		display: block;
		position: absolute;
		top: 22px;
		left: 0;
		height: 18px;
		opacity: 1;
		transition-property: visibility, opacity;
		transition-timing-function: cubic-bezier(0.32, 0.08, 0.24, 1);
		transition-duration: 0.6s;

		@media (min-width: $screen-md) {
			flex-shrink: 0;
			position: relative;
			top: auto;
			left: auto;
			height: 36px;
		}
	}

	&__short-logo-link {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			flex-shrink: 0;
			height: 36px;
		}
	}

	&__logo-img {
		height: 100%;
		width: auto;
		vertical-align: top;
	}

	&__short-logo-img {
		height: 100%;
		width: auto;
		vertical-align: top;
	}

	&__control {
		position: absolute;
		top: 27px;
		right: 0;
		z-index: 1;
		height: 61px;
		transform: translateY(-50%);

		@media (min-width: $screen-md) {
			display: none;
		}
	}

	&__player {
		display: none;

		@media (min-width: $screen-md) {
			display: block;
			margin-left: auto;
		}
	}

	&__nav-btn {
		position: absolute;
		top: calc(50% + 1px);
		right: -12px;
		z-index: 1;
		transform: translateY(-50%);
	}

	&__nav {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		overflow: hidden;

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			overflow-y: auto;
		}

		@media (min-width: $screen-md) {
			display: block !important;
			width: auto;
			height: auto;
			margin-left: 66px;
		}
	}

	&__nav-list {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;

		@media (min-width: $screen-md) {
			flex-direction: row;
		}
	}

	&__nav-item {
		margin-bottom: 31px;

		@media (max-width: $screen-md - 1) and (orientation: landscape) {
			margin-bottom: 12px;
		};

		@media (min-width: $screen-md) {
			margin-bottom: 0;
			margin-right: 54px;
		}

		&:last-child {
			margin-bottom: 0;

			@media (min-width: $screen-md) {
				margin-right: 0;
			}
		}

		&.animated {

			&:nth-child(1) {
				animation: fadeInUp 1s 0.6s cubic-bezier(.25, .1, .25, 1) both;
			}

			&:nth-child(2) {
				animation: fadeInUp 1s 0.8s cubic-bezier(.25, .1, .25, 1) both;
			}

			&:nth-child(3) {
				animation: fadeInUp 1s 1s cubic-bezier(.25, .1, .25, 1) both;
			}

			&:nth-child(4) {
				animation: fadeInUp 1s 1.2s cubic-bezier(.25, .1, .25, 1) both;
			}

			&:nth-child(5) {
				animation: fadeInUp 1s 1.4s cubic-bezier(.25, .1, .25, 1) both;
			}
		}
	}

	&__nav-link {
		display: inline-block;
		vertical-align: top;
		font-weight: 300;
		font-size: 32px;
		line-height: 40px;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
		color: $white;

		&::before {
			transition: opacity $transition-time;
		}

		@media (min-width: $screen-md) {
			font-weight: 400;
			font-size: 18px;
			line-height: 26px;
			user-select: none;
		}

		&:hover {
			color: $white;

			@media (min-width: $screen-md) {
				color: $link-color--hover;
			}

			&::before {

				@media (min-width: $screen-md) {
					opacity: 0.65;
				}
			}
		}

		&:focus {
			color: $white;
		}

		&:active {
			color: $white;

			@media (min-width: $screen-md) {
				color: $link-color--hover;
			}
		}

		&--icon_lock {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 3px;
				left: 100%;
				width: 21px;
				height: 21px;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAERSURBVHgB3ZS9SgNBFEa/FUnnDxaChZjeRhAbsdhHibWlhYKC6XwBH0AEG0HEInXKlEkXSBECgZAqCSlTZHICEwjLzE52E1LkwGHYy36XuTvDSjuDMSbGb2xgFUtaBxq8GTf/GCkrhIq2wRRf8BxvsGnrZWWF0LsNPyXqJzjEti+7Jz9ndq0tF6MoGrB08CJP0wVTR22Ult1XmPnpP7Ne2ma/eKg80OjTBPBl08Y/0iax12lswjwow05LeKAw966i76DuEs8NaxHjpfo1uy1wzSYKwYt/iTHLth47PkEhmfeN39JqtFy79DX90mp8KAuM9WjSqSsPBF+x52j4g8e+XPCfSPiU5RavsI8VvmNX22YGvyI5qSNrny0AAAAASUVORK5CYII=');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;

				@media (min-width: $screen-md) {
					top: 6px;
					width: 12px;
					height: 12px;
				}
			}
		}
	}

	&__copyright {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		visibility: hidden;
		transition-property: visibility;
		transition-duration: 0.3s;

		p {
			margin-bottom: 10px;
			font-weight: 200;
			font-size: 9px;
			line-height: 22px;
			text-transform: uppercase;
			color: rgba($white, 0.35);
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		overflow: hidden;

		@media (orientation: landscape) {
			overflow-y: auto;
		}
	}

	&__content-inner {
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		text-align: center;
	}

	&__content-logo {
		display: block;
		margin-bottom: 42px;

		&.animated {
			animation: fadeInUp 1s 0.6s cubic-bezier(.25, .1, .25, 1) both;
		}
	}

	&__content-text {
		margin-bottom: 94px;

		@media (orientation: landscape) {
			text-align: center;
		}

		&.animated {
			animation: fadeInUp 1s 0.8s cubic-bezier(.25, .1, .25, 1) both;
		}
	}

	&__content-btn {

		&.animated {
			animation: fadeInUp 1s 1s cubic-bezier(.25, .1, .25, 1) both;
		}

		+ #{$self}__content-btn {
			margin-top: 16px;
			animation: fadeInUp 1s 1.2s cubic-bezier(.25, .1, .25, 1) both;
		}
	}
}
