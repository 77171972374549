$height-icon: 8px;
$width-line: 25px;
$height-line: 1px;

$transition-time: 0.6s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;


.hamburger-btn {
	$self: &;
	display:block;
	background-color: transparent;
	position:relative;
/*	margin: ($height-icon * 2) auto $height-icon auto;*/
	padding: 12px;

	&__inner {
		position: relative;
		width:$width-line;
		height:$height-icon;
	}

	&__line {
		display:block;
		background: #ffffff;
		width:$width-line;
		height:$height-line;
		position:absolute;
		left:0;
		border-radius: 0;
		transition: all $transition-time;
	}

	&__line-1 {
		top:0;
	}
	&__line-2 {
		top:50%;
	}
	&__line-3 {
		top:100%;
	}

/*	&:hover, &:focus {
		.line-1 {
			transform: translateY($height-line / 2 * -1);
			-webkit-transform: translateY($height-line / 2 * -1);
			-moz-transform: translateY($height-line / 2 * -1);
		}
		.line-3 {
			transform: translateY($height-line / 2);
			-webkit-transform: translateY($height-line / 2);
			-moz-transform: translateY($height-line / 2);
		}
	}*/

	&.is-active {

		#{$self}__line-1 {
			transform: translateY($translateY) translateX($translateX) rotate($rotation);
		}

		#{$self}__line-2 {
			opacity:0;
		}

		#{$self}__line-3 {
			transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
		}
	}

}
